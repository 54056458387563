import { useState, useEffect } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import Link from "next/link";

import DropdownButton from "./DropdownButton";
import LoginModal from "./LoginModal";
import { useAuth } from "../hooks/useAuth";
import { formatNameAvatarText, capitalize } from "../util";
import Loader from "./Loader";
import Button from "./Button";
import LanguageSwitcher from "./LanguageSwitcher";

const Layout = ({
  children,
  subtitle,
  title,
  landing,
  withContainer,
  headerBackLink,
  loader,
  centered
}) => {
  const router = useRouter();
  const {
    user,
    tenant: tenant_,
    singleOrganization,
    colorPalette,
    logout,
    adminPreviewSettings,
    exitAdminPreview
  } = useAuth();
  const tenantLoaded = !!tenant_;
  let tenant = tenant_ || {};

  const isLoggedIn = !!user;
  const [loginOpen, setLoginOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [isApp, setIsApp] = useState(true);

  const { festivalId } = router.query;

  const navItems = [
    tenant.virtual_festival_show_catalog_page && {
      href: "/[festivalId]",
      as: `/${festivalId}`,
      title: tenant.virtual_festival_catalog_label || "Catalog"
    },
    tenant.virtual_festival_show_livestreams_page && {
      href: "/[festivalId]/live",
      as: `/${festivalId}/live`,

      title: tenant.virtual_festival_livestreams_label || "Live Streams"
    },
    tenant.virtual_festival_show_pass_sales && {
      href: "/[festivalId]/passes",
      as: `/${festivalId}/passes`,

      title:
        tenant.virtual_festival_pass_sales_label ||
        capitalize(tenant.display_passes) ||
        "Passes"
    },
    tenant.virtual_festival_show_search_page && {
      href: "/[festivalId]/search",
      as: `/${festivalId}/search`,

      title: "Search"
    }
  ].filter(i => !!i);

  useEffect(() => {
    const desired = `color-palette-${colorPalette}`;
    const badClasses = Array.from(document.body.classList).filter(
      c => c.startsWith("color-palette") && c !== desired
    );
    document.body.classList.add(desired);
    badClasses.forEach(c => document.body.classList.remove(c));
  }, [colorPalette]);

  useEffect(() => {
    if (tenant.virtual_festival_custom_css_stylesheet) {
      const existingEl = document.getElementById("custom-css-stylesheet");
      if (
        existingEl &&
        existingEl.href === tenant.virtual_festival_custom_css_stylesheet
      ) {
        return;
      }
      document.getElementById("__next").style.display = "none";
      const linkEl = existingEl || document.createElement("link");
      linkEl.id = "custom-css-stylesheet";
      linkEl.rel = "stylesheet";
      linkEl.href = tenant.virtual_festival_custom_css_stylesheet;
      linkEl.onload = () => {
        document.getElementById("__next").style.display = "block";
      };
      document.head.appendChild(linkEl);
    } else {
      const existingLinkEl = document.getElementById("custom-css-stylesheet");
      if (existingLinkEl) {
        existingLinkEl.remove();
      }
    }
  }, [tenant.virtual_festival_custom_css_stylesheet]);

  const hasCustomLogo =
    tenant.virtual_festival_hide_eventive_logo &&
    tenant.virtual_festival_eventive_logo_replacement;

  const footerImage =
    (router.pathname === "/[festivalId]" &&
      tenant.virtual_festival_catalog_footer_image) ||
    (router.pathname === "/[festivalId]/play/[...programId]" &&
      tenant.virtual_festival_screening_footer_image);
  return (
    <div data-iframe-height="">
      {loginOpen ? (
        <LoginModal
          allowBackgroundDimiss
          onSuccess={() => setLoginOpen(false)}
          onBackgroundDismiss={() => setLoginOpen(false)}
        />
      ) : null}
      {adminPreviewSettings ? (
        <div
          style={{
            background: `repeating-linear-gradient(145deg, #C62828, #C62828 10px, #B71C1C 10px, #B71C1C 20px)`
          }}
        >
          <div className="max-w-7xl p-4 lg:px-8 mx-auto flex">
            <div className="flex-1 text-always-white font-bold">
              ADMIN PREVIEW &middot; {adminPreviewSettings.name},{" "}
              {adminPreviewSettings.organization} &middot; FOR QA PURPOSES ONLY
              &middot; ALL USAGE MONITORED
              <div className="font-light text-sm italic">
                Abuse of the admin preview functionality for any purpose other
                than quality assurance is cause for termination of your Eventive
                account.
              </div>
            </div>
            <Button
              basic
              className="ml-2 self-center"
              onClick={() => exitAdminPreview()}
            >
              Exit preview
            </Button>
          </div>
        </div>
      ) : null}
      <div className="bg-gray-800 pb-32">
        <nav className="bg-gray-800">
          <div className="max-w-7xl mx-auto lg:px-8">
            <div className="border-b border-gray-700">
              <div className="flex items-center justify-between h-16 px-4 lg:px-0">
                <div className="flex items-center">
                  <div className="flex-shrink-0 text-center eventive__logo-container">
                    {hasCustomLogo || singleOrganization ? (
                      <Link href={hasCustomLogo ? `/${festivalId}` : "/"}>
                        <a className="font-bold text-lg text-white">
                          <span>
                            {tenant.virtual_festival_eventive_logo_replacement ||
                            singleOrganization.logo ? (
                              <img
                                key="custom_image"
                                className="eventive__logo-container__logo no-translate"
                                src={
                                  hasCustomLogo
                                    ? tenant.virtual_festival_eventive_logo_replacement
                                    : singleOrganization.logo
                                }
                                style={{ maxWidth: 120, maxHeight: 37 }}
                                alt={tenant.virtual_festival_display_name}
                              />
                            ) : (
                              <span key="custom_text">
                                {singleOrganization.name}
                              </span>
                            )}
                          </span>
                        </a>
                      </Link>
                    ) : (
                      <Link href="/">
                        <a
                          onClick={
                            headerBackLink
                              ? e => {
                                  e.preventDefault();
                                  router.back();
                                }
                              : undefined
                          }
                        >
                          <img
                            src={
                              colorPalette === "white"
                                ? "/images/eventive-logo-dark.png"
                                : "/images/eventive-logo.png"
                            }
                            style={{ height: 24, width: 120 }}
                            alt="Eventive"
                          />
                        </a>
                      </Link>
                    )}
                  </div>
                  <div className="hidden lg:block">
                    <div
                      className={classNames(
                        "flex items-baseline",
                        hasCustomLogo ? "ml-5" : "ml-10"
                      )}
                    >
                      {!landing && festivalId
                        ? navItems.map(({ href, as, title }, i) => (
                            <Link href={href} as={as} key={href}>
                              <a
                                className={classNames(
                                  "px-3 py-2 rounded-md text-sm font-medium",
                                  router.pathname === href
                                    ? "text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 no-translate"
                                    : "text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 no-translate",
                                  i > 0 && "ml-4"
                                )}
                              >
                                {title}
                              </a>
                            </Link>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block">
                  <div className="ml-4 flex items-center lg:ml-6">
                    {!isApp && !landing && festivalId && tenantLoaded ? (
                      <a
                        href={
                          tenant.virtual_festival_back_url ||
                          `https://${festivalId}.eventive.org/`
                        }
                        className={classNames(
                          tenant.virtual_festival_back_label &&
                            tenant.virtual_festival_back_label !==
                              "Back to Festival Site"
                            ? "px-3 py-2 rounded-md text-sm font-medium no-translate"
                            : "px-3 py-2 rounded-md text-sm font-medium",
                          "text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                        )}
                      >
                        {tenant.virtual_festival_back_label ||
                          "Back to Festival Site"}
                      </a>
                    ) : null}
                    <Link href="/help">
                      <a
                        className={classNames(
                          router.pathname === "/help"
                            ? "text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                            : "text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700",
                          "ml-4 px-3 py-2 rounded-md text-sm font-medium capitalize"
                        )}
                      >
                        Need help?
                      </a>
                    </Link>
                    {isLoggedIn ? (
                      <div className="ml-4 lg:ml-6 relative">
                        <DropdownButton
                          button={
                            <button className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid">
                              <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-400">
                                <span className="text-sm font-medium leading-none text-always-white no-translate">
                                  {formatNameAvatarText(
                                    user.details.name || user.details.email
                                  )}
                                </span>
                              </span>
                            </button>
                          }
                          dropdown={
                            <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-30">
                              <div className="py-1 rounded-md bg-always-white shadow-xs">
                                <div className="px-4 py-2 no-translate">
                                  <div className="text-sm font-semibold leading-none text-always-gray-500 break-words">
                                    {user.details.name}
                                  </div>
                                  <div className="mt-1 text-xs font-medium leading-none text-always-gray-400 break-words">
                                    {user.details.email}
                                  </div>
                                </div>
                                <Link href="/me">
                                  <a className="block px-4 py-2 text-sm text-always-gray-700 hover:bg-always-gray-100">
                                    My content library
                                  </a>
                                </Link>
                                {!singleOrganization?.use_aarp_registration_flow &&
                                !singleOrganization?.sundance_login ? (
                                  <a
                                    href={`https://${
                                      festivalId || "account"
                                    }.eventive.org/account`}
                                    className="block px-4 py-2 text-sm text-always-gray-700 hover:bg-always-gray-100"
                                  >
                                    Account settings
                                  </a>
                                ) : null}
                                <Link href="/tv">
                                  <a className="block px-4 py-2 text-sm text-always-gray-700 hover:bg-always-gray-100">
                                    TV app setup
                                  </a>
                                </Link>
                                <a
                                  onClick={() => logout()}
                                  className="block cursor-pointer px-4 py-2 text-sm text-always-gray-700 hover:bg-always-gray-100"
                                >
                                  Sign out
                                </a>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    ) : (
                      <button
                        onClick={() => setLoginOpen(true)}
                        className={classNames(
                          "pointer ml-4 px-3 py-2 rounded-md text-sm font-medium",
                          "text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                        )}
                      >
                        Login
                      </button>
                    )}
                  </div>
                </div>
                <div className="-mr-2 flex lg:hidden">
                  <button
                    onClick={() => setNavOpen(!navOpen)}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        className={classNames(
                          navOpen ? "hidden" : "inline-flex"
                        )}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                      <path
                        className={classNames(
                          navOpen ? "inline-flex" : "hidden"
                        )}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              navOpen ? "block" : "hidden",
              "border-b border-gray-700 lg:hidden"
            )}
          >
            <div className="px-2 py-3 sm:px-3">
              {!landing
                ? navItems.map(({ href, as, title }, i) => (
                    <Link href={href} as={as} key={href}>
                      <a
                        className={classNames(
                          "block px-3 py-2 rounded-md text-base font-medium",
                          router.pathname === href
                            ? "text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 no-translate"
                            : "text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 no-translate",
                          i > 0 && "mt-1"
                        )}
                      >
                        {title}
                      </a>
                    </Link>
                  ))
                : null}
              {!isApp && !landing && tenantLoaded ? (
                <a
                  href={
                    tenant.virtual_festival_back_url ||
                    `https://${festivalId}.eventive.org/`
                  }
                  className={classNames(
                    tenant.virtual_festival_back_label &&
                      tenant.virtual_festival_back_label !==
                        "Back to Festival Site"
                      ? "no-translate"
                      : "",
                    "mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  )}
                >
                  {tenant.virtual_festival_back_label ||
                    "Back to Festival Site"}
                </a>
              ) : null}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              {user ? (
                <div>
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-400">
                        <span className="text-md font-medium leading-none text-always-white no-translate">
                          {formatNameAvatarText(user.details.name)}
                        </span>
                      </span>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white no-translate">
                        {user.details.name}
                      </div>
                      <div className="mt-1 text-sm font-medium leading-none text-gray-400 no-translate">
                        {user.details.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2">
                    <Link href="/me">
                      <a className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                        My content library
                      </a>
                    </Link>
                    {festivalId &&
                    !singleOrganization?.use_aarp_registration_flow &&
                    !singleOrganization?.sundance_login ? (
                      <a
                        href={`https://${festivalId}.eventive.org/account`}
                        className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                      >
                        Account settings
                      </a>
                    ) : null}
                    <Link href="/tv">
                      <a className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                        TV app setup
                      </a>
                    </Link>
                    <Link href="/help">
                      <a className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                        Help
                      </a>
                    </Link>
                    <a
                      onClick={() => logout()}
                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              ) : (
                <div className="px-2 -mt-1">
                  <Link href="/help">
                    <a className="block cursor-pointer px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
                      Help
                    </a>
                  </Link>

                  <a
                    onClick={() => setLoginOpen(true)}
                    className="block cursor-pointer px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Login
                  </a>
                </div>
              )}
            </div>
          </div>
        </nav>
        {!landing && !loader && tenant ? (
          <header className={classNames("py-10", centered && "text-center")}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {subtitle ? (
                <div>
                  <h2 className="inline-block text-gray-400 text-xl no-translate">
                    {title || tenant.virtual_festival_display_name}
                  </h2>
                  <h3 className="text-3xl leading-10 font-bold text-white no-translate">
                    {subtitle}
                  </h3>
                </div>
              ) : (
                <h2 className="text-3xl leading-9 font-bold text-white no-translate">
                  {title ? (
                    title
                  ) : tenant.virtual_festival_header_logo ? (
                    <img
                      src={tenant.virtual_festival_header_logo}
                      alt={tenant.virtual_festival_display_name}
                      className={
                        tenant.virtual_festival_header_full_width
                          ? ""
                          : "max-h-16"
                      }
                    />
                  ) : (
                    tenant.virtual_festival_display_name
                  )}
                </h2>
              )}
            </div>
          </header>
        ) : null}
      </div>
      <main className="-mt-32">
        <div
          className={classNames(
            "max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8",
            loader && "flex flex-col items-center justify-center"
          )}
          style={{ minHeight: loader ? "80vh" : "65vh" }}
        >
          {loader ? (
            <Loader />
          ) : withContainer ? (
            <div className="bg-always-white rounded-lg shadow px-5 py-6 sm:px-6">
              {children}
            </div>
          ) : (
            children
          )}
        </div>
      </main>
      {footerImage ? (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <img src={footerImage} className="w-full" />
        </div>
      ) : null}
      <footer className="pt-6 pb-12">
        <LanguageSwitcher />
        {!singleOrganization?.full_white_label &&
        !tenant.virtual_festival_full_white_label ? (
          <div className="text-center text-gray-200 text-sm">
            <a href="https://eventive.org" target="_blank">
              Powered by Eventive
            </a>
          </div>
        ) : null}
        <div className="mt-1 text-center text-gray-300 text-xs">
          <Link href="/help">
            <a className="hover:underline">Help</a>
          </Link>{" "}
          &middot;{" "}
          <a
            className="hover:underline"
            href="https://eventive.org/terms/"
            target="_blank"
          >
            Terms
          </a>{" "}
          &middot;{" "}
          <a
            className="hover:underline"
            href="https://eventive.org/privacy/"
            target="_blank"
          >
            Privacy
          </a>{" "}
          &middot;{" "}
          <a
            className="hover:underline"
            href="https://status.eventive.org/"
            target="_blank"
          >
            System Status
          </a>
        </div>
      </footer>
      {tenant.virtual_festival_custom_css ? (
        <style>{tenant.virtual_festival_custom_css}</style>
      ) : null}
    </div>
  );
};

export default Layout;
